import type { DataPoint } from '@shopify/polaris-viz';
import { parse } from 'date-fns';
import { useGlobalState } from 'src/hooks/use-global-state';
import { useVizTheme } from 'src/hooks/use-viz-theme';

// import { LineChart } from './LineChart';
// import { BarChart } from './BarChart';

const parseDateString = (dateString: string) => {
  try {
    return parse(dateString, 'yyyy-MM-dd', new Date()).toISOString();
  } catch (error) {
    return dateString;
  }
};

export const SubscriberGainStatsChart = ({
  type,
}: {
  type: 'bar' | 'line';
}) => {
  const {
    state: {
      reports: { subscriberGrowthBreakdown },
    },
  } = useGlobalState('home');
  const vizTheme = useVizTheme();

  const subscriberGrowthVizDataPoints: DataPoint[] =
    subscriberGrowthBreakdown.map(dataPoint => {
      return {
        key: parseDateString(dataPoint.date),
        value: dataPoint.count,
      };
    });

  return null;
};
