import {
  BlockStack,
  Box,
  InlineStack,
  SkeletonDisplayText,
  Text,
  Tooltip,
} from '@shopify/polaris';
import InfoRoundedFilled from 'barn/icons/InfoRoundedFilled';
import Image from 'next/image';
import NumberFormat from 'src/components/NumberFormat';

type Props = {
  isFetching: boolean;
  value: number | React.ReactNode;
  isCurrency?: boolean;
  imageURL: string;
  label: string;
  children?: React.ReactNode;
  tooltip?: string;
};

export const KeyStat = ({
  isFetching,
  value,
  isCurrency = false,
  imageURL,
  label,
  children,
  tooltip = '',
}: Props) => {
  return (
    <BlockStack gap='100'>
      <InlineStack gap='200'>
        {isFetching ? (
          <Box minWidth='100px' minHeight='34px'>
            <SkeletonDisplayText size='large' />
          </Box>
        ) : (
          <Text as='h2' variant='heading2xl' fontWeight='regular'>
            {/* There was a line height change because of span in NumberFormat component when using currency 
                This span is added to keep the alignment same. We need to fix this issue in NumberFormat */}
            <span>
              {typeof value === 'number' ? (
                <NumberFormat value={value} isCurrency={isCurrency} />
              ) : (
                value
              )}
            </span>
          </Text>
        )}
        <Image width={30} height={30} src={imageURL} alt={label} />
      </InlineStack>
      <InlineStack gap='400'>
        <InlineStack blockAlign='center' gap='200' align='center'>
          <Text as='span' variant='bodyLg' tone='subdued'>
            {label}
          </Text>
          {tooltip && (
            <Tooltip preferredPosition='below' content={tooltip}>
              <span
                style={{
                  display: 'flex',
                }}
              >
                <InfoRoundedFilled
                  size={20}
                  color='var(--p-color-icon-secondary)'
                />
              </span>
            </Tooltip>
          )}
        </InlineStack>
        {children}
      </InlineStack>
    </BlockStack>
  );
};
