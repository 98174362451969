import { Grid } from 'barn/components/Grid';
import styled from 'styled-components';
import tokens from 'barn/tokens';
import { responsiveScreenBreakpoint } from 'barn/tokens/misc';
import { Card, Text, BlockStack } from '@shopify/polaris';

type Stat = {
  label: string;
  value: string | React.ReactNode;
};
interface Props {
  data: Array<Stat>;
}

const StyledStat = styled.div<{ isFirst: boolean }>`
  border-left: ${({ isFirst }) =>
    isFirst ? 'none' : `1px solid ${tokens.colors.greys[4]}`};
  padding-left: ${tokens.space.padding[6]};

  @media (max-width: ${responsiveScreenBreakpoint}) {
    border-left: none;
    padding-top: ${({ isFirst }) =>
      isFirst ? 'auto' : tokens.space.padding[5]};
  }
`;

const Stats: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <Card>
      <Grid templateColumns={`repeat(${data.length}, 1fr)`} gap={2} responsive>
        {data.map((stat: Stat, index: number) => (
          <StyledStat isFirst={index === 0} key={stat.label}>
            <BlockStack gap='200'>
              <Text as='span' fontWeight='regular' variant='headingXl'>
                {stat.value}
              </Text>
              <Text variant='bodyMd' as='span' tone='subdued'>
                {stat.label}
              </Text>
            </BlockStack>
          </StyledStat>
        ))}
      </Grid>
    </Card>
  );
};

export default Stats;
