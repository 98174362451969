import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useBrevoEmailEnabled } from 'src/hooks/use-brevo-email-enabled';
import { useFeatureFlag } from 'src/hooks/use-feature-flag';
import { useGlobalState } from 'src/hooks/use-global-state';
import { FeaturedTile } from './FeaturedTile';
import { UpgradeTile } from './UpgradeTile';

export const HomeTile = () => {
  const router = useRouter();
  const { t } = useTranslation('webpush');
  const isEmailEnabled = useBrevoEmailEnabled();
  const { isLoading, data } = useFeatureFlag(
    isEmailEnabled ? 'HometileEmailBeta' : 'Hometile',
  );
  const {
    state: { consumption },
  } = useGlobalState('settings');

  const { impressionsConsumed = 0, impressionsLimit = 100 } = consumption || {};

  const percentage = parseInt(
    ((impressionsConsumed / impressionsLimit) * 100).toFixed(0),
    10,
  );

  if (percentage >= 80) {
    return <UpgradeTile percentage={percentage} />;
  }

  if (data && data[router.locale]) {
    const localalizedText = data[router.locale];

    return (
      <FeaturedTile
        data={{
          ...localalizedText,
          isExternalLink: data.isExternalLink,
          endDate: data.endDate,
          imageURL: data.imageURL,
          link: data.link,
        }}
      />
    );
  }

  if (isLoading) {
    return <FeaturedTile isLoading data={null} />;
  }

  return (
    <FeaturedTile
      data={{
        isExternalLink: false,
        endDate: null,
        title: t(
          'Consistently engage your customers to build a lasting relationship and drive repeat business',
        ),
        imageURL: '/static/img/new_campaing_card.png',
        buttonText: t('Send a campaign'),
        link: '/campaigns/create/1',
      }}
    />
  );
};
