import dynamic from 'next/dynamic';
import { Fragment, useMemo } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import { Props } from './types';

export const Loader = (props: Props) => {
  const {
    state: { isAuthenticating },
  } = useGlobalState('user');

  const ReportSaver = useMemo(() => {
    if (isAuthenticating) return Fragment;

    return dynamic(() => import('./Component'), { ssr: false });
  }, [isAuthenticating]);

  if (isAuthenticating) return null;

  return <ReportSaver {...props} />;
};

export { Loader as ReportSaver };
