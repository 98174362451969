import { Toast } from 'src/components/Toast';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { PromotionPopups } from 'src/modules/promotion-popups/views';
import { useShowPricing } from 'src/hooks/use-show-pricing';
import Wrapper from '../Wrapper';
import Content from '../Content';
import Stats from './Stats';
import TitleBar from '../Titlebar';
import { Navigation } from '../Navigation';

const DefaultLayout = ({ children }) => {
  useShowPricing();

  return (
    <>
      <Navigation />
      <Wrapper>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Wrapper>
      <Toast />

      <PromotionPopups />
    </>
  );
};

DefaultLayout.TitleBar = TitleBar;
DefaultLayout.Content = Content;
DefaultLayout.Stats = Stats;

export { DefaultLayout };
