import { Fragment, useMemo } from 'react';
import { Spinner } from '@shopify/polaris';
import dynamic from 'next/dynamic';
import { useGlobalState } from 'src/hooks/use-global-state';
import { IDaterangeFilter } from './Component';

const Loader = (props: IDaterangeFilter) => {
  const {
    state: { isAuthenticating },
  } = useGlobalState('user');

  const HistoricalDaterangePicker: any = useMemo(() => {
    if (isAuthenticating) return Fragment;

    return dynamic(() => import('./Component'), { ssr: false });
  }, [isAuthenticating]);

  if (isAuthenticating) return <Spinner size='small' />;

  return <HistoricalDaterangePicker {...props} />;
};

export { Loader as HistoricalDaterangePicker };
