import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  SkeletonBodyText,
  Text,
} from '@shopify/polaris';
import { ExternalIcon } from '@shopify/polaris-icons';
import { differenceInDays } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { Skeleton } from 'src/components/Skeleton';
import { useGlobalState } from 'src/hooks/use-global-state';

export const FeaturedTile = ({
  data,
  isLoading = false,
  isIntegratedDashboard = false,
}) => {
  const { t } = useTranslation('webpush');
  const router = useRouter();
  const {
    state: { user },
  } = useGlobalState('user');

  if (isLoading) {
    return (
      <Card padding='0'>
        <Skeleton width='100%' height='131px' />

        <Box padding='500'>
          <BlockStack gap='400'>
            <Box minHeight='60px'>
              <SkeletonBodyText />
            </Box>
            <Button disabled>{t('Loading')}</Button>
          </BlockStack>
        </Box>
      </Card>
    );
  }

  const numberOfDays = data.endDate
    ? differenceInDays(new Date(data.endDate), new Date())
    : 0;

  return (
    <Card padding='0'>
      <Box position='relative'>
        {data.featuredText ? (
          <Box
            insetBlockStart='200'
            insetInlineStart='200'
            position='absolute'
            background='bg-surface'
            opacity='0.8'
            padding='100'
            paddingInlineStart='200'
            paddingInlineEnd='200'
            borderRadius='050'
          >
            <InlineStack gap='100'>
              {numberOfDays > 0 && (
                <Text as='span' variant='bodyMd' fontWeight='semibold'>
                  {numberOfDays}
                </Text>
              )}
              <Text as='span' variant='bodyMd' fontWeight='semibold'>
                {data.featuredText}
              </Text>
            </InlineStack>
          </Box>
        ) : null}
      </Box>

      <div
        style={{
          background: `url(${data.imageURL}) center/cover no-repeat`,
          height: '130px',
          display: 'block',
          width: '100%',
          backgroundPosition: isIntegratedDashboard ? '50% 20%' : '',
        }}
      />

      <Box padding='500'>
        <BlockStack gap='400'>
          <Box minHeight='60px'>
            <Text as='span'>{data.title}</Text>
          </Box>
          <Button
            id='featured-tile-button'
            url={
              data.isExternalLink
                ? `${data.link}#subdomain=${user.website.subdomain}`
                : null
            }
            onClick={() => {
              if (!data.isExternalLink) {
                if (data.buttonTrackingEvent) {
                  posthog.capture(data.buttonTrackingEvent);
                }
                router.push(data.link);
              }
            }}
            target={data.isExternalLink ? '_blank' : '_self'}
            icon={data.isExternalLink ? ExternalIcon : undefined}
          >
            {data.buttonText || ''}
          </Button>
        </BlockStack>
      </Box>
    </Card>
  );
};
