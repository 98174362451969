import { BlockStack, Box, Button, Card, Text } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';
import { useBrevoEmailEnabled } from 'src/hooks/use-brevo-email-enabled';
import { useGlobalState } from 'src/hooks/use-global-state';

export const UpgradeTile = ({ percentage }: { percentage: number }) => {
  const { t } = useTranslation('webpush');

  const isNewPricingEnabled = useBrevoEmailEnabled();
  const {
    actions: { showNewPricing },
  } = useGlobalState('pricing');
  const { actions: pricingV2Actions } = useGlobalState('pricingV2');

  const getDescription = () => {
    if (percentage < 100) {
      return 'You’re about to consume your monthly impression limit. Upgrade and reach out to more people than ever before';
    }

    return 'You have consumed your monthly impression limit. Upgrade and reach out to more people than ever before';
  };

  return (
    <Card padding='0'>
      <div
        style={{
          background: `url('/static/img/upgrade-${
            percentage === 80 ? 80 : 100
          }.png') center/cover no-repeat`,
          height: '120px',
          display: 'block',
          width: '100%',
        }}
      />
      <Box padding='500'>
        <BlockStack gap='300'>
          <Box minHeight='60px'>
            <Text as='span'>{getDescription()}</Text>
          </Box>
          <Button
            onClick={() =>
              isNewPricingEnabled
                ? pricingV2Actions.goToPricing({
                    utmMedium: 'upgrade_tile',
                  })
                : showNewPricing({ utmMedium: 'upgrade_tile' })
            }
          >
            {t('Upgrade')}
          </Button>
        </BlockStack>
      </Box>
    </Card>
  );
};
