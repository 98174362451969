import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useGlobalState } from './use-global-state';

export function useFeatureFlag(flag: string) {
  const client = usePostHog();
  const [isLoading, setIsLoading] = useState(true);
  const [featureFlagPayload, setFeatureFlagPayload] = useState<any>();
  const {
    state: {
      segmentData: { isSupportLogin },
    },
  } = useGlobalState('user');

  useEffect(() => {
    return client.onFeatureFlags(() => {
      setFeatureFlagPayload(client.getFeatureFlagPayload(flag));
      setIsLoading(false);
    });
  }, [client, flag]);

  return { isLoading: isLoading && !isSupportLogin, data: featureFlagPayload };
}
