import dynamic from 'next/dynamic';
import { Fragment, useMemo } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';

export const AppSetup = () => {
  const {
    state: { isThemeAppExtensionEnabled },
  } = useGlobalState('home');

  const AppSetupCard = useMemo(() => {
    if (!isThemeAppExtensionEnabled) {
      return dynamic(() => import('./AppSetupCard'), { ssr: false });
    }
    return Fragment;
  }, [isThemeAppExtensionEnabled]);

  return <AppSetupCard />;
};
