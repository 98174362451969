/* eslint-disable no-return-assign */
import useAuth from './use-auth';

/**
 * displayReportSaver: Report Saver modal
 * displayCampaignFilters: Campaign Types and Segments filters on the Campaigns page
 * displayReportSaverAttrWindow: Attribution Window in the Report Saver modal
 * displayGlobalAttrWindow: Global Attribution Window in Settings
 * displayReports: Reports in Settings
 */
interface Flags {
  displayReportSaver: boolean;
  displayCampaignFilters: boolean;
  displayReportSaverAttrWindow: boolean;
  displayGlobalAttrWindow: boolean;
  displayReports: boolean;
}

const useReportingFeatureFlags = (): Flags => {
  const {
    user: {
      website: {
        website_plan: {
          pricing_tier: pricingTier,
          enable_custom_attribution: enableCustomAttribution = false,
        },
      },
    },
  } = useAuth();

  const flags: Flags = {
    displayReportSaver: true,
    displayCampaignFilters: true,
    displayReportSaverAttrWindow: true,
    displayGlobalAttrWindow: true,
    displayReports: true,
  };

  if (pricingTier === 'free')
    Object.keys(flags).forEach(key => (flags[key] = false));

  if (pricingTier === 'business') {
    flags.displayCampaignFilters = false;

    // Disable custom attribution flags if enableCustomAttribution is false
    if (!enableCustomAttribution) {
      flags.displayGlobalAttrWindow = false;
      flags.displayReportSaverAttrWindow = false;
    }
  }

  return flags;
};

export default useReportingFeatureFlags;
