import dynamic from 'next/dynamic';
import { Fragment, useMemo } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';

const Loader = () => {
  const {
    state: { isAuthenticating },
  } = useGlobalState('user');
  const CompletedGettingStarted = useMemo(() => {
    if (isAuthenticating) return Fragment;

    return dynamic(() => import('./Component'), { ssr: false });
  }, [isAuthenticating]);

  return <CompletedGettingStarted />;
};

export { Loader as CompletedGettingStarted };
