import { SkeletonDisplayText } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';
import ReactNumberFormat from 'react-number-format';
import useAuth from 'src/hooks/use-auth';
import { useGlobalState } from 'src/hooks/use-global-state';

interface Props {
  displayType?: 'input' | 'text';
  value: number;
  isCurrency?: boolean;
  renderText?: (formattedValue: string) => React.ReactNode;
  fixedDecimalScale?: boolean;
  showPositiveSign?: boolean;
  asPercentage?: boolean;
}
const NumberFormat: React.FunctionComponent<Props> = ({
  value,
  displayType = 'text',
  isCurrency = false,
  renderText = null,
  fixedDecimalScale = false,
  showPositiveSign = false,
  asPercentage = false,
}) => {
  const { t } = useTranslation('common');

  const { user } = useAuth();
  const {
    state: { isAuthenticating },
  } = useGlobalState('user');

  if (isAuthenticating) return <SkeletonDisplayText />;

  return (
    <>
      {showPositiveSign && value >= 0 ? '+' : ''}
      <ReactNumberFormat
        value={
          value !== null ? value : 0
        } /* if api response contains `null`, set value to 0 */
        displayType={displayType}
        thousandSeparator={t('thousandSeparator')}
        renderText={
          isCurrency
            ? text =>
                (user.currency || '').replace(
                  user.amountPlaceholderText || '',
                  text,
                )
            : renderText
        }
        decimalScale={isCurrency && value > 999 ? 0 : 2}
        fixedDecimalScale={fixedDecimalScale}
      />
      {asPercentage && value >= 0 ? t('%') : null}
    </>
  );
};

export default NumberFormat;
