import { BlockStack, Text } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';

const Welcome = () => {
  const { t } = useTranslation('webpush');

  return (
    <BlockStack gap='100'>
      <Text as='h2' fontWeight='medium' variant='headingXl'>
        {t('Hi there! 👋🏻')}
      </Text>
      <Text as='p' fontWeight='regular' variant='headingLg'>
        {t('Welcome to PushOwl')}
      </Text>
    </BlockStack>
  );
};

export default Welcome;
