import dynamic from 'next/dynamic';
import { Fragment, useMemo } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';

export const Loader = () => {
  const {
    state: { isAuthenticating },
  } = useGlobalState('user');

  const SubscribersProgress = useMemo(() => {
    if (isAuthenticating) return Fragment;
    return dynamic(() => import('./Component'), { ssr: false });
  }, [isAuthenticating]);

  return <SubscribersProgress />;
};

export { Loader as SubscribersProgress };
