import { useEffect } from 'react';
import { useGlobalState } from './use-global-state';

/**
 * callback to be called when global attribution window
 * settings have been fetched on app load.
 *
 * To be used on pages which use these global settings
 * and might be the first page loaded in the session.
 */
const useAfterAttributionWindow = (callback: Function) => {
  const {
    state: {
      attributionWindow: { isFetching },
    },
  } = useGlobalState('settings');

  useEffect(() => {
    if (!isFetching) callback();
  }, [isFetching]);
};

export default useAfterAttributionWindow;
