import { BlockStack } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import useAfterAttributionWindow from 'src/hooks/use-after-attribution-window';
import { useGlobalState } from 'src/hooks/use-global-state';
import { DefaultLayout } from 'src/layouts/default';
import gettingStartedSteps from 'src/modules/getting-started/constants/steps';
import { CompletedGettingStarted } from '../../getting-started/components/CompletedGettingStarted';
import { AppSetup } from '../components/AppSetup';
import KeyStats from '../components/KeyStats';
import { LastNotification } from '../components/LastNotification';
import { SubscribersProgress } from '../components/SubscribersProgress';
import SubscriberStats from '../components/SubscriberStats';
import Welcome from '../components/Welcome';

const Webpush: React.FunctionComponent = () => {
  const { t } = useTranslation('webpush');

  const {
    state: { isAuthenticating },
  } = useGlobalState('user');
  const {
    state: {
      reports: {
        filters: { timeFilter, granularity },
      },
    },
    actions,
  } = useGlobalState('home');

  const {
    state: { step },
  } = useGlobalState('gettingStarted');
  const {
    actions: { fetchBanners },
  } = useGlobalState('banners');

  useAfterAttributionWindow(actions.fetchTotalAttributedRevenue);

  // fetch reports on initial load
  useEffect(() => {
    if (!isAuthenticating) {
      Promise.all([
        actions.fetchReport(),
        actions.fetchSubscriberGrowthReport({
          timeWindow: timeFilter[2].value,
          granularity,
        }),
      ]).then(() => {
        fetchBanners();
      });
    }
  }, [isAuthenticating]);

  return (
    <DefaultLayout>
      <DefaultLayout.TitleBar title={t('Dashboard')} />
      <DefaultLayout.Content>
        <BlockStack gap='600'>
          <Welcome />
          <AppSetup />
          <SubscribersProgress />
          <KeyStats />
          <SubscriberStats />
          <LastNotification />
        </BlockStack>
      </DefaultLayout.Content>
      {step === gettingStartedSteps.COMPLETED && <CompletedGettingStarted />}
    </DefaultLayout>
  );
};

export default Webpush;
