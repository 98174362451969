import {
  BlockStack,
  Button,
  ButtonGroup,
  Card,
  InlineStack,
  Link,
  Select,
  Spinner,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { ChartLineIcon, ChartVerticalFilledIcon } from '@shopify/polaris-icons';
import { format } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { HistoricalDaterangePicker } from 'src/components/HistoricalDaterangePicker';
import {
  DaysInSeconds,
  TimeFilter,
} from 'src/components/HistoricalDaterangePicker/types';
import NumberFormat from 'src/components/NumberFormat';
import { ReportSaver } from 'src/components/ReportSaver';
import {
  ReportsGranularity,
  reportsGranularity,
  ReportTypes,
} from 'src/components/ReportSaver/models';
import useAuth from 'src/hooks/use-auth';
import { useGlobalState } from 'src/hooks/use-global-state';
import useReportingFeatureFlags from 'src/hooks/use-reporting-feature-flags';
import { SubscriberGainStatsChart } from './SubscriberGainStatsChart';

const SubscriberStats = () => {
  const { t } = useTranslation('webpush');

  const [graphType, setGraphType] = useState<'bar' | 'line'>('bar');

  const {
    state: {
      reports: {
        isFetching,
        totalSubsGained,
        filters: { timeFilter, granularity },
      },
    },
    actions,
  } = useGlobalState('home');
  const {
    user: {
      website: { created_at: createdAt },
    },
  } = useAuth();
  const { displayReportSaver } = useReportingFeatureFlags();

  // timeFilter is [Date, Date, {value, label, type}], with [0] and [1], the start and end dates selected
  const firstDate =
    timeFilter[2].value === DaysInSeconds.ALL_TIME
      ? new Date(createdAt)
      : timeFilter[0];

  const translatedReportsGranularity = reportsGranularity.map(granularity => {
    return { ...granularity, label: t(granularity.label) };
  });

  return (
    <BlockStack gap='400'>
      <BlockStack gap='300'>
        <Text as='h3' variant='headingLg' fontWeight='regular'>
          {t('Subscriber gain')}
        </Text>
        <Text tone='subdued' as='p' fontWeight='regular'>
          {t('Subscribers gathered from')}{' '}
          <Link monochrome url='/optins'>
            {t('Opt-ins')}
          </Link>
        </Text>
        <InlineStack gap='300' blockAlign='center' wrap align='space-between'>
          {isFetching ? (
            <Spinner size='small' />
          ) : (
            <HistoricalDaterangePicker
              // TODO:aftermigration - remove type assertion
              state={
                {
                  type: timeFilter[2].type,
                  label: t(timeFilter[2].label),
                  value: timeFilter[2].value,
                } as TimeFilter
              }
              onChange={(...timeFilter) => {
                actions.updateFiltersAndFetchGrowthReport({ timeFilter });
              }}
            />
          )}
          {displayReportSaver && (
            <ReportSaver
              reportType={ReportTypes.SUBSCRIBER_GROWTH}
              name={t('Subscriber name')}
              // TODO:aftermigration - remove type assertion
              sourceTimeFilter={timeFilter[2] as TimeFilter}
            />
          )}
        </InlineStack>
      </BlockStack>
      <Card>
        <BlockStack gap='400'>
          <InlineStack align='space-between' wrap>
            <InlineStack blockAlign='center' gap='300'>
              <Text as='span' fontWeight='regular' variant='headingXl'>
                <NumberFormat value={totalSubsGained} showPositiveSign />
              </Text>
              <InlineStack blockAlign='baseline' gap='200'>
                <Text as='span' variant='bodySm'>
                  {format(firstDate, 'd MMM yy')} -{' '}
                  {format(timeFilter[1], 'd MMM yy')}
                </Text>
                <Text as='span' variant='bodySm'>
                  {t(timeFilter[2].label) || t('Custom Date')}
                </Text>
              </InlineStack>
            </InlineStack>
            <InlineStack blockAlign='center' gap='400'>
              <ButtonGroup variant='segmented'>
                <Tooltip
                  content={t('Switch to line chart')}
                  preferredPosition='below'
                >
                  <Button
                    onClick={() => setGraphType('line')}
                    size='slim'
                    pressed={graphType === 'line'}
                    icon={ChartLineIcon}
                    aria-label='line chart'
                  />
                </Tooltip>
                <Tooltip
                  content={t('Switch to bar chart')}
                  preferredPosition='below'
                >
                  <Button
                    onClick={() => setGraphType('bar')}
                    size='slim'
                    pressed={graphType === 'bar'}
                    icon={ChartVerticalFilledIcon}
                    aria-label='bar chart'
                  />
                </Tooltip>
              </ButtonGroup>
              <span>
                {isFetching ? (
                  <Spinner size='small' />
                ) : (
                  <Select
                    value={granularity}
                    options={translatedReportsGranularity}
                    onChange={value => {
                      actions.updateFiltersAndFetchGrowthReport({
                        granularity: value as ReportsGranularity,
                      });
                    }}
                    name='Granularity'
                    label={t('Granularity')}
                    labelHidden
                  />
                )}
              </span>
            </InlineStack>
          </InlineStack>
          <div
            style={{
              height: '350px',
            }}
            className='chart-container'
          >
            <SubscriberGainStatsChart type={graphType} />
          </div>
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default SubscriberStats;
