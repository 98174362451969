import { BlockStack, Box, Card, Divider, Grid, Text } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';
import NumberFormat from 'src/components/NumberFormat';
import { useGlobalState } from 'src/hooks/use-global-state';
import { HomeTile } from './HomeTile';
import { KeyStat } from './KeyStat';

const KeyStats = () => {
  const { t } = useTranslation('webpush');

  const {
    state: {
      isFetching,
      summary,
      reports: { totalAttributedRevenue },
    },
  } = useGlobalState('home');
  const {
    state: { consumption },
  } = useGlobalState('settings');

  return (
    <Grid
      columns={{
        xs: 1,
        md: 1,
        lg: 3,
        xl: 3,
      }}
    >
      <Grid.Cell>
        <HomeTile />
      </Grid.Cell>
      <Grid.Cell>
        <Box borderRadius='050'>
          <Card padding='1000'>
            <BlockStack gap='800'>
              <KeyStat
                isFetching={isFetching}
                isCurrency
                label={t('Revenue generated')}
                value={totalAttributedRevenue}
                imageURL='/static/svg/Indicator/RevenueGenerated.svg'
              />
              <Divider borderColor='border' borderWidth='050' />
              <BlockStack gap='200'>
                <KeyStat
                  isFetching={isFetching}
                  value={summary?.subscribers?.total}
                  imageURL='/static/svg/Indicator/SubscribersCard.svg'
                  label={t('Subscribers')}
                  tooltip={t('Your Email and SMS subscribers are synced')}
                />
              </BlockStack>
            </BlockStack>
          </Card>
        </Box>
      </Grid.Cell>
      <Grid.Cell>
        <Box borderRadius='050'>
          <Card padding='1000'>
            <BlockStack gap='800'>
              <KeyStat
                isFetching={isFetching}
                value={summary?.campaigns?.sent}
                imageURL='/static/svg/Indicator/CampaignsSent.svg'
                label={t('Total campaigns sent')}
              />
              <Divider borderColor='border' borderWidth='050' />
              <BlockStack gap='200'>
                <KeyStat
                  isFetching={consumption.isFetching}
                  label={t('Impressions consumed')}
                  value={
                    <>
                      <NumberFormat value={consumption.impressionsConsumed} />{' '}
                      <Text
                        as='span'
                        variant='headingXl'
                        fontWeight='regular'
                        tone='subdued'
                      >
                        /<NumberFormat value={consumption.impressionsLimit} />
                      </Text>
                    </>
                  }
                  imageURL='/static/svg/Indicator/Impressions.svg'
                />
              </BlockStack>
            </BlockStack>
          </Card>
        </Box>
      </Grid.Cell>
    </Grid>
  );
};

export default KeyStats;
